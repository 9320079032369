<template>
	<data-loader :watch="utility && date && {utility,date} || null" :callback="loadData" :data.sync="data">
		<template #loading>
			<v-progress-linear indeterminate />
		</template>
		<ep-list v-if="data?.length" dense>
			<ep-panel v-for="item in data" :key="item.id">
				<ep-header>
					{{ item.users.map(e => e.name).join(', ') }}
				</ep-header>
				<ep-content>
					<key-val-table compact dense style="white-space: pre-wrap;" :data="[
						{key: 'areas', label: 'Райони', val: item.areas.map(e => e.name).join(', ')},
						{key: 'type', label: 'Тип група', val: item.typeLabel},
						{key: 'notes', label: 'Забележки', val: item.notes},
					]" />
					<v-divider/>
					<v-sheet rounded v-for="job in item.jobs" :key="job.id" class="pa-2 mt-2" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'">
						<div class="body-2">
							<div class="font-weight-bold">{{ job.start }} - {{ job.end }}: {{job.statusLabel}}</div>
							#{{ job.imot_N }}, {{ job.address }}
							<div v-html="job.services_summary" />
							<div v-if="job.notes_operator != ''">
								<strong>Бележки оператор:</strong><br>
								<div style="white-space: pre-wrap;">{{job.notes_operator}}</div>
							</div>
						</div>
					</v-sheet>
				</ep-content>
				<v-divider/>
			</ep-panel>
		</ep-list>
		<v-alert v-else-if="data" type="info" text outlined dense class="ma-3">
			Няма монтажни групи
		</v-alert>
		<div v-else />
	</data-loader>
</template>

<script>
import DataLoader from "@/ittijs/components/DataLoader.vue";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import Model from "@/views/montaji/schedule/Model";
import {TimeOfDay} from "@/ittijs/utils";
import ExpansionPanelShort from "@/ittijs/ExpansionPanelShort";
import KeyValTable from "@/ittijs/components/KeyValTable.vue";

const model = ITTIModelProvider.getModel(Model);

export default {
	mixins: [
		SelectedUtilityMixin,
		ExpansionPanelShort,
	],
	components : {KeyValTable, DataLoader},
	props: {
		utility: {},
		date: {},
		noBorder: Boolean,
	},
	data(){
		return {
			model,
			data: null,
			workStart: window.ITTIConfig.montajiWorkStart,
			workEnd: window.ITTIConfig.montajiWorkEnd,
		}
	},
	methods: {
		async loadData(){
			return await this.model.getDayData(this.utility, this.date);
		},
		jobsToEvents(jobs) {
			return jobs
				.map(job => {
					const start = TimeOfDay.fromString(job.scheduled_time_start.substr(0, 5));
					const end = start.addMinutes(parseInt(job.scheduled_duration_minutes));
					return {
						start,
						end,
						job,
					}
				});
		},
		onClickEvent(event) {
			console.log(event);
		},
	},
}
</script>
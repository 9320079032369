<template>
	<v-container>
		<please-select-utility>
			<data-loader
				:watch="{selectedUtility,calendarStartDate,calendarEndDate}"
				:callback="loadData"
				:data.sync="calendarData"
			>
				<template #default="{reload}">
					<div>

						<v-toolbar dense rounded class="mb-4" elevation="2">
							<button-set dense>
								<v-btn @click="calendarPrev">
									<v-icon>mdi-chevron-left</v-icon>
								</v-btn>
								<v-btn @click="calendarNext">
									<v-icon>mdi-chevron-right</v-icon>
								</v-btn>
							</button-set>
							<v-divider vertical class="mx-4" />
							<v-toolbar-title>{{ calendarTitle }}</v-toolbar-title>
							<v-spacer/>
							<v-switch v-model="showWeekend" label="Събота/Неделя" hide-details></v-switch>
						</v-toolbar>

						<v-calendar
							ref="calendar"
							type="month"
							@change="onCalendarChange"
							@click:event="onEventClick($event)"
							v-model="calendarDate"
							:events="calendarEvents"
							:short-weekdays="false"
							:short-months="false"
							:short-intervals="false"
							:weekdays="weekdays"
							locale="bg"
							:event-height="80"
							:event-more="false"
							:event-color="getEventColor"
						>
							<template #day="{date, outside, past}">
								<!-- make space for absolutely positioned button -->
								<div style="height: 50px;" />

								<my-dialog :title="`График за ${formatDate(date)}`" width="800">
									<template #activator="{attrs,on}">
										<v-btn v-bind="attrs" v-on="on"
											small depressed absolute style="left: 8px; bottom: 8px;"
										>
											<v-icon color="grey">mdi-list-box-outline</v-icon>
										</v-btn>
									</template>
									<view-daily-schedule :utility="selectedUtility" :date="date" no-border class="pa-2" />
								</my-dialog>

								<v-menu v-if="!outside && !past">
									<template #activator="{attrs,on}">
										<v-btn v-bind="attrs" v-on="on" @click="addDate = date"
											small depressed absolute style="right: 8px; bottom: 8px;"
										>
											<v-icon left color="grey">mdi-plus-circle</v-icon>
											Добави
										</v-btn>
									</template>
									<template #default="{value}">
										<v-list dense v-if="value">
											<v-list-item v-if="dateGroups.length===0" disabled>Няма групи</v-list-item>
											<v-list-item
												v-for="(group, index) in dateGroups"
												:key="index"
												@click="onAddToDay(date, group)"
												:disabled="group.disabled"
											>{{ group.name }}</v-list-item>
											<v-list-item @click="onAddToDay(date, {})">
												[ без група ]
											</v-list-item>
										</v-list>
									</template>
								</v-menu>
							</template>
							<template #event="{event}">
								<div class="ma-2">
									<strong>{{ event.users.map(e => e.name).join(', ') }}</strong><br>
									{{ event.areas.map(e => e.name).join(', ') }}
									<day-jobs-indicator
										:events="event.jobs"
										:start="ITTIConfig.montajiWorkStart"
										:end="ITTIConfig.montajiWorkEnd"
										@jobclick="onJobClick"
									>
										<template #tooltip="{job}">
											<time-period-format :start="job.start" :end="job.end" />: имот {{job.imot_N}}<br>
											{{job.address}}
										</template>
									</day-jobs-indicator>
								</div>
							</template>
						</v-calendar>

						<my-dialog v-model="dialog" title="График" max-width="700">
							<Edit v-if="dialog" :imodel="model" hide-title
								:left-col-width="100"
								:params="editParams"
								:constrains="editConstraints"
								:init="editDefaults"
								fields="type,users_ids,allow_unplanned_jobs,notes,areas_ids"
								@close="dialog = false"
								@delete="reload()"
								@saveclose="dialog = false; reload();"
								hidden-fields="utility_code,date"
							>
							</Edit>
						</my-dialog>

					</div>
				</template>
			</data-loader>
		</please-select-utility>
	</v-container>
</template>
<script>
/* eslint-disable @typescript-eslint/camelcase */

import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility.vue";
import DataLoader from "@/ittijs/components/DataLoader.vue";
import Model from "./Model";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import MyDialog from "@/ittijs/components/MyDialog.vue";
import ButtonSet from "@/ittijs/components/ButtonSet.vue";
import Edit from "@/ittijs/EditColumn.vue";
import {isoToDate, KeyQueue, TimeOfDay} from "@/ittijs/utils";
import {colorPalette12, months, weekdays} from "@/ittijs/Nomenclatures";
import DayJobsIndicator from "@/views/montaji/schedule/DayJobsIndicator.vue";
import TimePeriodFormat from "@/ittijs/components/TimePeriodFormat.vue";
import ViewDailySchedule from "@/views/montaji/schedule/ViewDailySchedule.vue";

const model = ITTIModelProvider.getModel(Model);

const colorsQueue = new KeyQueue(colorPalette12);
colorsQueue.infinite = true;

export default {
	mixins: [
		SelectedUtilityMixin,
	],
	components: {
		ViewDailySchedule,
		TimePeriodFormat,
		DayJobsIndicator,
		Edit,
		MyDialog,
		ButtonSet,
		PleaseSelectUtility,
		DataLoader,
	},
	data: () => ({
		calendarData: null,
		calendarTitle: null,
		calendarStartDate: null,
		calendarEndDate: null,
		calendarDate: null,
		showWeekend: false,
		model,
		addDate: null,
		dialog: false,
		editParams: null,
		editConstraints: null,
		editDefaults: null,
	}),
	computed: {
		TimeOfDay: () => TimeOfDay,
		ITTIConfig: () => window.ITTIConfig,
		weekdays(){
			return [1,2,3,4,5, ...(this.showWeekend ? [6,0] : [])];
		},
		dateGroups(){
			return this.calendarUserGroups.map(g => ({
				id: g.id,
				name: g.users.map(e => e.name).join(', '),
				type: g.type,
				users_ids: g.users.map(e => `${e.id}`),
				disabled: this.isGroupDisabledForDay(g, this.addDate),
			}));
		},
		calendarUserGroups(){
			return this.calendarData && this.calendarData.groups || [];
		},
		calendarEvents(){
			return this.calendarData && this.calendarData.records || [];
		},
	},
	methods: {
		formatDate(date){
			date = isoToDate(date);
			if (date) {
				return `${weekdays[date.getDay()]}, ${date.getDate()} ${months[date.getMonth()]}`;
			}
			return null;
		},
		isGroupDisabledForDay(group, date){
			return this.calendarEvents
				.filter(e => e.start === date)
				.some(e => e.users.some(
					u => group.users.some(
						u2 => u2.id === u.id
					)
				))
			;
		},
		onCalendarChange($event){
			this.calendarTitle = this.$refs.calendar.title;
			this.calendarStartDate = $event.start.date;
			this.calendarEndDate = $event.end.date;
		},
		calendarPrev(){
			this.$refs.calendar.prev();
		},
		calendarNext(){
			this.$refs.calendar.next();
		},
		async loadData(){
			await model.fieldsAsync;
			return await model.getCalendarData(
				this.selectedUtility,
				this.calendarStartDate,
				this.calendarEndDate,
			);
		},
		async onAddToDay(date, group) {
			this.editParams = {
				id: 'new'
			};
			this.editDefaults = {
				utility_code: this.selectedUtility,
				date: date,
				type: group.type,
				users_ids: group.users_ids,
			};
			this.editConstraints = {
				utility_code: this.selectedUtility,
				date: date,
			};
			this.dialog = true;
		},
		onEventClick(event) {
			if (event.day.past) throw "Не могат да се редактират отминали дни";
			this.editParams = {
				id: event.event.id
			};
			this.editDefaults = {};
			this.editConstraints = {};
			this.dialog = true;
		},
		onJobClick(job){
			console.log(job);
		},
		getEventColor(event){
			return colorsQueue.getByKey(event.users.map(e => e.id).sort().join(', '));
		},
	},
}
</script>